import Keycloak from "keycloak-js";
import { KeycloakConfig, RedirectUri } from "common/constants";

const keycloak = new Keycloak({
	url: KeycloakConfig.url,
	realm: KeycloakConfig.realm,
	clientId: KeycloakConfig.clientId,
	redirectUri: RedirectUri,
});

export default keycloak;
