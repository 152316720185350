import axios from "axios";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useTranslation } from "react-i18next";
import * as Constants from "common/constants";

import { EncryptStorage } from "encrypt-storage";
const { useEffect, useState } = require("react");

const Translation = ({
	withWizard,
	form,
	mainLanguage,
	setMyDirection,
	languages,
	translationUrl,
	formioFormRef,
	setResources,
	setReadyState,
	setMainLanguage,
	readyState,
	formKey,
}) => {
	const encryptStorage1 = new EncryptStorage("secret-key-value", {
		prefix: "@mwan",
	});
	const [language, setLanguage] = useState("");
	const [direction, setDirection] = useState("");
	const [t, i18n] = useTranslation();
	//initialize the initial language of the form
	useEffect(() => {
		if (languages) {
			let details = languages?.filter((lng) => lng.value === i18n.language.substring(0, 2))[0];
			if (details) {
				if (mainLanguage !== i18n.language.substring(0, 2)) {
					setLanguage(i18n.language.substring(0, 2));
				}
			} else {
				let newLanguage = languages.length !== 0 ? languages[0].value : i18n.language.substring(0, 2);
				if (mainLanguage !== newLanguage) {
					setLanguage(newLanguage);
				}
			}
		}
	}, [i18n.language]);

	//get form labels translation
	useEffect(() => {
		if (translationUrl) {
			let tempResources = {};
			for (let i = 0; i < languages.length; i++) {
				tempResources[languages[i].value] = {};
			}

			var headers = { "Content-Type": "application/json" };

			var accessToken = encryptStorage1.getItem(Constants.AccessTokenKeyInLocalStorage);
			if (accessToken) {
				var headerToken = { Authorization: "Bearer " + accessToken };
				headers = { ...headers, ...headerToken };
			}

			fetch(translationUrl + "/select=*", { method: "GET", headers: headers })
				.then((response) => response.json())
				.then((res) => {
					if (res) {
						let translatedData = res.data.dataBaseContent;
						for (let i = 0; i < languages.length; i++) {
							for (let j = 0; j < translatedData.length; j++) {
								tempResources[languages[i].value][translatedData[j].LabelID] = translatedData[j][languages[i].value];
							}
						}

						setResources(tempResources);
						form.settings["translatedData"] = tempResources;
						if (withWizard === false) {
							form["display"] = "form";
						}
						setReadyState(true);
					}
				})
				.then(() => {})
				.catch((error) => {
					if (withWizard === false) {
						form["display"] = "form";
					}
					setReadyState(true);
				});
		} else {
			if (withWizard === false) {
				form["display"] = "form";
			}
			setReadyState(true);
		}
	}, [translationUrl]);
	// useEffect(() => {
	//     if (formioFormRef.current) {
	//         form.settings["language"] = language;;
	//         formioFormRef.current.language = language;
	//         formioFormRef.current.element.setAttribute('dir', formioFormRef.current.i18next.dir());
	//         setDirection(formioFormRef.current.i18next.dir())

	//     }
	// }, [formioFormRef.current])

	const handleMainLanguageChange = (lng) => {
		if (formioFormRef.current && lng) {
			if (!form.settings) {
				form["settings"] = {};
			}
			form.settings["language"] = lng.value;
			formioFormRef.current.language = lng.value;
			formioFormRef.current.element.setAttribute("dir", formioFormRef.current.i18next.dir());
			setDirection(formioFormRef.current.i18next.dir());

			setMyDirection(formioFormRef.current.i18next.dir());
			// formioFormRef.current.triggerRedraw();
		}
	};

	useEffect(() => {
		if (language !== "") {
			form.settings["language"] = language;

			setMainLanguage(language);
		}
	}, [language]);

	useEffect(() => {
		let details = {};
		if (languages && languages.length > 0) {
			details = languages?.filter((lng) => lng.value === language)[0];
		} else {
			details = { value: i18n.language.substring(0, 2) };
		}
		handleMainLanguageChange(details);
	}, [formKey, formioFormRef.current]);
	// return readyState && form.display !== null && form.display !== undefined && languages?.length > 1 && translationUrl && translationUrl?.toString().replaceAll(" ", "") !== "" && <div dir={direction}>

	//     <Tabs
	//         id="controlled-tab-example"
	//         activeKey={language}
	//         onSelect={(k) => {
	//             setLanguage(k);
	//         }}
	//         className="mb-3"
	//     >
	//         {
	//             languages?.map((lng) => {
	//                 return <Tab eventKey={lng.value} title={lng.translation[language]} />
	//             })
	//         }

	//     </Tabs>

	// </div>
	return <></>;
};
export default Translation;
