import React, { useEffect, useState, Suspense } from "react";
import keycloak from "KeycloakConf";
import { EncryptStorage } from "encrypt-storage";
import * as Constants from "./common/constants";
import * as General from "common/general";
import jwtDecode from "jwt-decode";
import db from "indexedDB";

class KeycloakService {
	constructor() {
		this.keycloakInstance = null;
		//  this.UserCompaniesComponent = null;
	}

	initializeKeycloak() {
		const encryptStorage1 = new EncryptStorage("secret-key-value", {
			prefix: "@mwan",
		});

		const GetLoginPageSuccess = (responseObject) => {};

		// Callback for failed API response
		const GetLoginPageFailure = () => {};

		return new Promise((resolve, reject) => {
			//      const loadComponent = () => import('components/usercompanies/GetUserCompanies');
			//    this.UserCompaniesComponent = React.lazy(loadComponent);

			this.keycloakInstance = keycloak;
			this.keycloakInstance
				.init({
					checkLoginIframe: false,
					pkceMethod: "S256",
					redirectUri: Constants.RedirectUri,
					onLoad: "login-required",
				})
				.then(async (auth) => {
					if (!auth) {
						localStorage.clear();
						console.info("Not Authenticated");

						reject("Not Authenticated");
					} else {
						// Make the API call here using useEffect hook to fetch the login component

						var domainNameResponse = await getDomainName();
						localStorage.setItem("domain", domainNameResponse.DomainName);
						localStorage.setItem("domain_id", domainNameResponse.id);

						encryptStorage1.setItem(Constants.AccessTokenKeyInLocalStorage, this.keycloakInstance.token);
						encryptStorage1.setItem(Constants.RefreshTokenKeyInLocalStorage, this.keycloakInstance.refreshToken);

						const decodedAccessToken = jwtDecode(this.keycloakInstance.token);

						var loggedInUserData = await General.GetLoggedInUserData(decodedAccessToken.sub);

						encryptStorage1.setItem(Constants.LoggedInUserInfoKeyInLocalStorage, loggedInUserData);

						encryptStorage1.setItem("keycloakObject", JSON.stringify(this.keycloakInstance));

						this.keycloakInstance.onTokenExpired = () => {
							console.log("token expired");
						};

						var userInfo = encryptStorage1.getItem(Constants.LoggedInUserInfoKeyInLocalStorage);
						

						var userCompanies = await General.GetUserCompanies(decodedAccessToken.sub);
						localStorage.setItem(
							"loggedInUserInfo",
							JSON.stringify({
								fullName: loggedInUserData.fullName,
								email: loggedInUserData.email,
								mobileNumber: loggedInUserData.mobileNumber,
								idNumber: loggedInUserData.idNumber,
								userId: decodedAccessToken.sub,
								companyId: userCompanies.length > 0 ? userCompanies[0].companyId : null,
								firstName: loggedInUserData.firstName,
								lastName: loggedInUserData.lastName,
								companyName: userCompanies.length > 0 ? userCompanies[0].companyName : null,
							})
						);

						if (userCompanies.length === 1) {
							encryptStorage1.setItem(Constants.SelectedCompany, userCompanies[0].companyId);
							encryptStorage1.setItem(Constants.SelectedKeycloakCompany, userCompanies[0].keycloakCompanyId);
						}
						const hasAccessTo = (path) => {
							return Object.values(userInfo.userCompanyDictionary).some(company =>
								company.some(item =>
									item.permissionResponseList.some(permission =>
										permission.menuResponseList.some(menu => menu.to === path)
									)
								)
							);
						};

						const hasWorkflowList = hasAccessTo("/workflowList");
						const hasUserTask = hasAccessTo("/userTasks");

						var currentWorkflowStep = await db.current_workflow_step.toArray();
						if (currentWorkflowStep.length === 0) {
							if (hasWorkflowList) {
								window.location.href = "/workflowList";
							} else if (hasUserTask) {
								window.location.href = "/userTasks";
							} else {
								window.location.href = "/workflowHistory";
							}
						}

						resolve();
					}
				})
				.catch((ex) => {
					console.error("Authentication Failed", ex);
					reject("Authentication Failed");
				});
		});
	}

	changeLanguage(language) {
		const encryptStorage1 = new EncryptStorage("secret-key-value", {
			prefix: "@mwan",
		});
		let oldSession = JSON.parse(JSON.stringify(encryptStorage1.getItem("keycloakObject")));
		oldSession.checkLoginIframe = false;
		oldSession.locale = language;
		oldSession.language = language;
	}

	logout() {
		const encryptStorage1 = new EncryptStorage("secret-key-value", {
			prefix: "@mwan",
		});
		let oldSession = JSON.parse(JSON.stringify(encryptStorage1.getItem("keycloakObject")));
		oldSession.checkLoginIframe = false;
		const Keycloak = keycloak;
		Keycloak.init(oldSession)
			.then(() => {
				let dbConnection;
				const requestOpen = indexedDB.open("MwanLocalDB");

				requestOpen.onsuccess = function (event) {
					dbConnection = event.target.result;
					dbConnection.close();
					const request = indexedDB.deleteDatabase("MwanLocalDB");
					request.onsuccess = function () {
						console.log("successfully");
					};
					request.onerror = function (event) {
						console.error("Error:", event);
					};
				};

				requestOpen.onerror = function (event) {
					console.error("Error open:", event);
				};
				var isrtl = localStorage.getItem("isRTL");
				var lang = localStorage.getItem("i18nextLng");
				localStorage.clear();
				localStorage.setItem("isRTL", isrtl);
				localStorage.setItem("i18nextLng", lang);
				Keycloak.logout({ redirectUri: Constants.RedirectUri });
			})
			.catch((error) => {
				// Handle any errors during initialization
				localStorage.clear();
				window.location.href = "/";
				// console.error("Keycloak initialization error:", error);
			});
	}

	renewSession() {
		return new Promise((resolve, reject) => {
			const encryptStorage1 = new EncryptStorage("secret-key-value", {
				prefix: "@mwan",
			});

			let oldSession = JSON.parse(JSON.stringify(encryptStorage1.getItem("keycloakObject")));
			oldSession.checkLoginIframe = false;
			const Keycloak = keycloak;

			try {
				Keycloak.init(oldSession)
					.then(() => {
						Keycloak.updateToken(1000000)
							.then(async (refreshed) => {
								if (refreshed) {
									encryptStorage1.setItem(Constants.AccessTokenKeyInLocalStorage, Keycloak.token);
									encryptStorage1.setItem(Constants.RefreshTokenKeyInLocalStorage, Keycloak.refreshToken);
									encryptStorage1.setItem("keycloakObject", JSON.stringify(Keycloak));

									const decodedAccessToken = jwtDecode(encryptStorage1.getItem(Constants.AccessTokenKeyInLocalStorage));
									var loggedInUserData = await General.GetLoggedInUserData(decodedAccessToken.sub);
									encryptStorage1.setItem(Constants.LoggedInUserInfoKeyInLocalStorage, loggedInUserData);
									localStorage.setItem(
										"loggedInUserInfo",
										JSON.stringify({
											fullName: loggedInUserData.fullName,
											email: loggedInUserData.email,
										})
									);

									// Continue with your application logic

									resolve(); // Resolve the promise on success
								}
							})
							.catch(() => {
								this.logout();
							});
					})
					.catch((error) => {
						this.logout();
						console.error("Keycloak initialization error:", error);
						reject(error); // Reject the promise on initialization error
					});
			} catch (ex) {}
		});
	}

	getKeycloakInstance() {
		return this.keycloakInstance;
	}
}

export async function getDomainName() {
	const domainName = window.location.hostname;

	const endPoint = `${Constants.GetEndPoint}/LoginPages/DomainName/${domainName}`;
	let response = null;
	await fetch(endPoint, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	})
		.then((res) => res.json())
		.then((data) => {
			response = data;
		})
		.catch((err) => {
			console.log(err.message);
		});
	return response;
}

export default new KeycloakService();
