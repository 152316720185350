// constants.js
//var base_url = 'https://localhost:7258';
//var base_url = 'https://insight2-mwanapi.azurewebsites.net';
//var base_url = 'https://api2.msisolutions.co.uk';
//var base_url = "https://localhost:44349";

//export const base_url = "https://localhost:44349";
export const base_url='https://api.mwan.gov.sa';
export const ExportPdfUrl = 'http://pdfgenerator.msisolutions.co.uk/form';
// export const ExportPdfUrl = "http://localhost:5000/form";

export const KeycloakConfig = {
	// GCP Live
	 url: "https://authsystem.mwan.gov.sa/",
	 newCompanyKeycloakId: "f58a29e1-8b57-4141-b83e-e8d5b6a6bb1a",

	// GCP Staging
	// url: window.location.origin + "/identity/",
	// newCompanyKeycloakId: "f58a29e1-8b57-4141-b83e-e8d5b6a6bb1a",

	// Prod
	//url: "https://authsystem.msisolutions.co.uk/",
	//newCompanyKeycloakId: "f58a29e1-8b57-4141-b83e-e8d5b6a6bb1a",
	// DEV
	//url: "https://authsystem-dev.msisolutions.co.uk/",
	//newCompanyKeycloakId: "05055cd8-a4e5-48b1-8464-407204a32347",

	// Common
	realm: "MWAN",
	clientId: "abp",
};

export const GetkeycloackConnectionInformationfUrl = base_url + "/api/Authentication/GetkeycloackConnectionInformation";
export const RedirectUri = window.location.origin;

export const LoginEndPoint = base_url + "/api/Authentication/Login";

export const GetGisTokenData = base_url + "/api/Integration/GetGisToken";

export const CurrentWorkflowStep = "currentWorkflowStep";
export const HasRedirected = "hasRedirected";

export const GetLoggedInUserData = base_url + "/api/Authentication/GetLoggedInUserData/";
export const GetCompanyUsersData = base_url + "/api/Authentication/GetCompanyUsersData/";

export const GetCompanyRolesData = base_url + "/api/Authentication/GetCompanyRoles/";
export const GetAllRolesDTOEndPoint = base_url + "/api/Authentication/GetAllRolesDTO/";

export const DeleteCompanyRolesData = base_url + "/api/Authentication/DeleteRoles";

export const AddUserData = base_url + "/api/Authentication/AddUser/";

export const UnassignUserData = base_url + "/api/Authentication/UnassignUser/";
export const SelectedCompany = "selectedCompany";
export const SelectedKeycloakCompany = "selectedKeycloakCompany";

export const GetUserCompaniesData = base_url + "/api/Authentication/GetUserCompanies/";
export const DeleteTransporterGeneratorData = base_url + "/api/Authentication/DeleteTransporterGenerator/";
export const AddTransporterGeneratorData = base_url + "/api/Authentication/AddTransporterGenerator/";
export const GetTransporterGeneratorsData = base_url + "/api/Authentication/GetTransporterGenerators/";
export const EditTransporterGeneratorsData = base_url + "/api/Authentication/EditTransporterGenerator/";

export const AddTransporterTruckData = base_url + "/api/Authentication/AddTransporterTruck/";
export const GetTransporterTrucksData = base_url + "/api/Authentication/GetTransporterTrucks/";
export const DeleteTransporterTruckData = base_url + "/api/Authentication/DeleteTransporterTruck/";
export const EditTransporterTruckData = base_url + "/api/Authentication/EditTransporterTruck/";

export const AddTransporterContactData = base_url + "/api/Authentication/AddTransporterGeneratorContact/";
export const EditTransporterContactData = base_url + "/api/Authentication/EditTransporterGeneratorContact/";
export const DeleteTransporterContactData = base_url + "/api/Authentication/DeleteTransporterGeneratorContact/";
export const GetTransporterContactsData = base_url + "/api/Authentication/GetTransporterGeneratorContacts/";
export const GetCRInformationByCRNumberData = base_url + "/api/Integration/GetCRInformationByCRNumber/";

export const GetCompaniesByType = base_url + "/api/Authentication/GetCompaniesByType/";
export const CreateCompanyEndPoint = base_url + "/api/Authentication/CreateCompany";

export const SendOtpEndPoint = base_url + "/api/Authentication/SendOtp";

export const CheckTokenEndPoint = base_url + "/api/Authentication/CheckToken";
export const GetAllUsersEndPoint = base_url + "/api/Authentication/GetAllUsers";
export const GetUserEndPoint = base_url + "/api/Authentication/GetUser/#ID#";
export const CreateUserEndPoint = base_url + "/api/Authentication/CreateUser";
export const CreateSubmissionEndPoint = base_url + "/api/Forms/createSubmission";

export const EditUserEndPoint = base_url + "/api/Authentication/EditUser/";
export const DeleteUserEndPoint = base_url + "/api/Authentication/DeleteUser/#ID#";
export const UpdatePasswordEndPoint = base_url + "/api/Authentication/UpdatePassword/";
export const UpdateForgottenPasswordEndPoint = base_url + "/api/Authentication/ChangeForgottenPassword";
export const ForgotPasswordRequestEndPoint = base_url + "/api/Authentication/ForgotPasswordRequest";
export const EditUserWithoutIdEndPoint = base_url + "/api/Authentication/EditUser";
export const GetUserCompanyByEmailEndpoint = base_url + "/api/DataBase/GetCompanyByUserEmail";

export const GetAllGroupsEndPoint = base_url + "/api/group/GetAllGroups";
export const GetAllUsersInAGroupEndPoint = base_url + "/api/group/GetAllUsersInGroup/#GroupID#";
export const GetAllGroupsByUser = base_url + "/api/group/GetAllGroupsByUser/#UserID#";
export const CreateGroupEndPoint = base_url + "/api/group/CreateGroup";

export const CompaniesGroupID = "bd062c6f-3474-4378-abc4-81346054d1ab";

export const GetAllRolesEndPoint = base_url + "/api/Authentication/GetAllRoles?briefRepresentation=false";
export const CreateRoleEndPoint = base_url + "/api/Authentication/CreateRole";
export const EditRoleEndPoint = base_url + "/api/Authentication/EditRole";
export const AssignRolesUsersEndPoint = base_url + "/api/Authentication/AssignRolesUsers";
export const UnassignRolesFromUserEndPoint = base_url + "/api/Authentication/UnassignRolesFromUser";
export const DeleteRoleEndPoint = base_url + "/api/Authentication/DeleteRole";
export const UserRolesEndPoint = base_url + "/api/Authentication/GetUserRoles/#ID#";

export const StartProcessEndPoint = base_url + "/api/Workflow/StartProcess/";
export const GetExecutionLocalVariableEndPoint = base_url + "/api/Workflow/GetExecutionLocalVariables";

export const StartProcessByDefinitionKeyEndPoint = base_url + "/api/Workflow/StartProcessByDefinitionKey/";
export const GetWorkflowListByAssigneeEndPoint = base_url + "/api/Workflow/GetAllWorkflowsByAssignee/";

export const GetPendingTasksEndPoint = base_url + "/api/Workflow/PendingTasks/";

export const GetWorkflowListByGroupsEndPoint = base_url + "/api/Workflow/GetAllWorkflowsByGroups/";
export const GetWorkflowListByUsersEndPoint = base_url + "/api/Workflow/GetAllWorkflowsByUsers/";

export const GetWorkflowListHistoryByAssigneeEndPoint = base_url + "/api/Workflow/GetWorkflowsHistoryByAssignee/";
export const GetWorkflowsHistoryByProcessInstance = base_url + "/api/Workflow/GetWorkflowsHistoryByProcessInstance/";

export const GetWorkflowsSubprocessEndPoint = base_url + "/api/Workflow/GetWorkflowsSubprocess/";
export const GetTaskEndPoint = base_url + "/api/Workflow/GetUserTask/";

export const VerificationEmailProcessDefinitionKey = "verification_workflow";
export const GetAllWorflowsEndpoint = base_url + "/api/Workflow/GetAllWorkflows/";
export const GetWorkflowsEndpoint = base_url + "/api/Workflow/GetWorkflows";
export const DeleteWorkflowEndPoint = base_url + "/api/Workflow/DeleteDeployment/";
export const UploadWorkflowEndPoint = base_url + "/api/Workflow/UploadAllWorkflows/";
export const CompleteTaskEndPoint = base_url + "/api/Workflow/CompleteTask/";
export const GetFormByUserTaskEndPoint = base_url + "/api/Workflow/GetFormByUserTask/";
export const GetExternalServiceTaskEndPoint = base_url + "/api/Workflow/GetExternalTask/";
export const FetchAndLockEndPoint = base_url + "/api/Workflow/FetchAndLockExternalTask/";
export const CompleteExternalTaskEndPoint = base_url + "/api/Workflow/CompleteExternalTask/";
export const GetWorkflowDiagramEndPoint = base_url + "/api/Workflow/GetDiagram/";
export const ClaimTaskEndPoint = base_url + "/api/Workflow/ClaimTask/";
export const GetTaskVariables = base_url + "/api/Workflow/GetTaskVariables/";
export const DeleteProcessInstance = base_url + "/api/workflow/DeleteProcessInstance/";

export const VerifyEmailValidityEndPoint = base_url + "/api/DataBase/verify-email-validity";

export const PutProcessInstanceVariableEndPoint = base_url + "/api/Workflow/PutProcessInstanceVariable";

export const ModifyProcessInstanceVariableEndPoint = base_url + "/api/Workflow/ModifyProcessInstanceVariable";

export const setAssignEndPoint = base_url + "/api/Workflow/SetAssignee/";

export const GetCountTaskByAssigneeEndPoint = base_url + "/api/Workflow/GetCountTaskByAssignee/";
export const GetCountUnAssignedTaskEndPoint = base_url + "/api/Workflow/GetCountUnAssignedTask/";
export const GetAllTemplatesEndPoint = base_url + "/api/Template/GetTemplates";
export const DownloadTemplateEndPoint = base_url + "/api/Template/DownloadTemplate";

export const GetRenderEndPoint = base_url + "/api/Template/GetRender";

export const AddTemplateEndPoint = base_url + "/api/Template/AddTemplate";
export const DeleteTemplateEndPoint = base_url + "/api/Template/DeleteTemplate";

export const GetFormByIdEndPoint = base_url + "/api/Forms/form/";

export const GetAllFormsEndPoint = base_url + "/api/Forms/getForms";
export const GetFormByPathEndPoint = base_url + "/api/Forms/getFormbyPath/#FORMPATH#";
export const CreateFormEndPoint = base_url + "/api/Forms/createForm";
export const EditFormEndPoint = base_url + "/api/Forms/editForm/#FORMID#";
export const DeleteFormEndPoint = base_url + "/api/Forms/deleteForm/#FORMPATH#";
export const GetAllSubmissionsEndPoint = base_url + "/api/Forms/form/#FORMID#/submission";
export const GetAllSubmissionByIdEndPoint = base_url + "/api/Forms/getForms";

export const SendNotificationEndPoint = base_url + "/api/Notifications/send-notification";

export const SendSmsEndPoint = base_url + "/api/Integration/SendSmsMessage";

export const SubmitWorkflowEndPoint = base_url + "/api/Workflow/SubmitWorkflow";

export const PostEndPoint = base_url + "/api/DataBase/Post";
export const GetEndPoint = base_url + "/api/DataBase/Get";
export const GetAllEndPoint = base_url + "/api/DataBase/GetAll";
export const DeleteDBEndPoint = base_url + "/api/DataBase/Delete/#TABLENAME#/#ITEMID#";
export const PutEndPoint = base_url + "/api/DataBase/Put";
export const PatchEndPoint = base_url + "/api/DataBase/Patch";
export const GetMenu = base_url + "/api/DataBase/GetMenu/";
export const GetAllResourcesEndPoint = base_url + "/api/DataBase/GetAllResources";
export const DeleteDBRecordEndPoint = base_url + "/api/DataBase/DeleteRecordDTO/";

export const CompanyTypesTableName = "companytype";
export const CompaniesTableName = "company";
export const LanguageTableName = "Languages";
export const SiteMapsTableName = "Menu";
export const SystemPropertiesTableName = "system_properties";
export const LabelsTableName = "Labels";

export const AccessTokenKeyInLocalStorage = "accessToken";
export const RefreshTokenKeyInLocalStorage = "refreshToken";
export const LoggedInEmailOrUserNameKeyInLocalStorage = "loggedInEmailOrUserName";
export const LoggedInRolesAttributesKeyInLocalStorage = "loggedInRolesAttributes";
export const LoggedInUserInfoKeyInLocalStorage = "loggedInUserInfo";

export const platform = "platform";

export var RoleAttributes = [
	{ id: "Dashboards", name: "Dashboards", checked: false },
	{ id: "Users", name: "Users", checked: false },
	{ id: "Roles", name: "Roles", checked: false },
	{ id: "Forms", name: "Forms", checked: false },
	{ id: "Workflows", name: "Workflows", checked: false },
];
export const RegistrationProcessDefinitionKey = "registration_workflow";
export const TestProcessDefinitionKey = "service_process";

export const SuccessStatus = 1;
export const FailureStatus = 0;
export const LogoutStatus = -1;

export var Language = "en";
export const FormLanguages = [
	{
		label: "English",
		value: "en",
		translation: {
			en: "English",
			ar: "الانكليزية",
			fr: "Anglais",
		},
	},
	{
		label: "Arabic",
		value: "ar",
		translation: {
			en: "Arabic",
			ar: "العربية",
			fr: "Arabe",
		},
	},
	// {
	//     label: "French",
	//     value: "fr",
	//     translation: {
	//         en: "French",
	//         ar: "الفرنسية",
	//         fr: "Francais"
	//     }
	// },
];

export const VerifyEmailEndPoint = base_url + "/api/DataBase/verify-email";
