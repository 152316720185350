import React, { Component, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { ReactComponent, Utils } from "@formio/react";
import settingsForm from "./NormalSelectAPI.settingsFrom";
import CustomSelect from "../customSelect";
import _ from "lodash";
import { createRoot } from "react-dom/client";
import { getRandomId } from "helpers/utils";
import * as Constants from "common/constants";
import { EncryptStorage } from "encrypt-storage";

/*
This component behaves as normal html select where user provides the endpoint with additional feature where its value can be filled in another 
component
*/

const NormalSelectCustomComp = ({ ...props }) => {
	const encryptStorage1 = new EncryptStorage("secret-key-value", {
		prefix: "@mwan",
	});
	// Update the setValue method to handle onChange event
	const updateValue = (e) => {
		if (props.form._form.settings && props.form._form.settings.toEdit !== true) {
			const newValue = e.target.value; // Get the selected value from the event
			let selectedValues = props.oldState["options"].filter((v) => v.value === newValue)[0];
			let state = props.oldState;
			if (props.component.relatedGrid) {
				props.form._data[props.component.relatedGrid] = [];
				props.form._submission.data[props.component.relatedGrid] = [];
				Utils.eachComponent(
					props.form.components,
					function (component) {
						if (component.component.key === props.component.relatedGrid) {
							component.editRows = [];
							component.addRow();
						}
					},
					true
				);
			}
			if (selectedValues) {
				state["value"]["defaultValue"] = newValue;
				if (props.component.componentsToFill) {
					for (let i = 0; i < props.component.componentsToFill.length; i++) {
						state["value"][props.component.componentsToFill[i]["fieldApi"]] = selectedValues.label;
					}
				}
				props.setOldState(state);
				props.component.defaultValue = {
					label: selectedValues.label,
					value: newValue,
				};
				props.form._data[props.component.key] = {
					label: selectedValues.label,
					value: newValue,
				};
				if (props.component.componentsToFill) {
					for (let i = 0; i < props.component.componentsToFill.length; i++) {
						props.form._data[props.component.componentsToFill[i]["fieldApi"]] = selectedValues?.label;
						props.form._submission.data[props.component.componentsToFill[i]["fieldApi"]] = selectedValues?.label;
					}
					// props.form.triggerRedraw();
				}
				if (props.component.keysToAdd) {
					let newJson = {};
					for (let i = 0; i < props.component.keysToAdd.length; i++) {
						newJson[props.component.keysToAdd[i].fieldApi] = selectedValues[props.component.keysToAdd[i].fieldApi];
					}
					props.onChange({ label: selectedValues.label, value: newValue, ...newJson }, null);
					props.form._data[props.component.key] = {
						label: selectedValues.label,
						value: newValue,
						...newJson,
					};
				} else {
					props.onChange({ label: selectedValues.label, value: newValue }, null);
				}
			} else {
				state["value"]["defaultValue"] = "";
				props.component.defaultValue = "";
				props.setOldState(state);
				props.onChange({}, null);
				props.form._data[props.component.key] = "";
				if (props.component.componentsToFill) {
					for (let i = 0; i < props.component.componentsToFill.length; i++) {
						state["value"][props.component.componentsToFill[i]["fieldApi"]] = "";
						props.form._data[props.component.componentsToFill[i]["fieldApi"]] = "";
						props.form._submission.data[props.component.componentsToFill[i]["fieldApi"]] = "";
					}
					// props.form.triggerRedraw();
				}
			}
			Utils.eachComponent(
				props.form.components,
				function (component) {
					if (component.component.input) {
						let shouldBeTrigerred = false;
						if (props.component.componentsToFill) {
							let indexOfComponent = props.component.componentsToFill.findIndex((c) => c.fieldApi === component.component.key);
							if (indexOfComponent >= 0) {
								shouldBeTrigerred = true;
							}
						}
						if (component.component.conditionalKey) {
							if (component.component.conditionalKey?.split(",").indexOf(props.component.key) >= 0) {
								shouldBeTrigerred = true;
							}
						}

						if (shouldBeTrigerred) {
							component.triggerRedraw();
						}
					} else if (component.component.type === "columns") {
						Utils.eachComponent(
							component.components,
							function (component2) {
								if (component2.component.input) {
									let shouldBeTrigerred = false;
									if (props.component.componentsToFill) {
										let indexOfComponent = props.component.componentsToFill.findIndex((c) => c.fieldApi === component.component.key);
										if (indexOfComponent >= 0) {
											shouldBeTrigerred = true;
										}
									}
									if (component2.component.conditionalKey) {
										if (component2.component.conditionalKey?.split(",").indexOf(props.component.key) >= 0) {
											shouldBeTrigerred = true;
										}
									}

									if (shouldBeTrigerred) {
										component2.triggerRedraw();
									}
								}
							},
							true
						);
					}
				},
				true
			);
		}
	};
	useEffect(() => {
		let state = props.oldState;

		// if (props.value !== props.oldState.value) {
		//     state["value"]["defaultValue"] = props.value
		//     props.setOldState(state);

		// }
		if (!props.component.initialShow) {
			props.component.label = "";
		}
		if (props.component.defaultValue && props.component.defaultValue !== "") {
			let label = props.data[props.component.key]?.label || props.component.defaultValue.label;
			let val = props.data[props.component.key]?.value || props.component.defaultValue.value;
			if (props.form._form.settings) {
				if (props.form._form.settings.translatedData) {
					if (props.form._form.settings.translatedData[props.form._form.settings.language]) {
						label = props.form._form.settings.translatedData[props.form._form.settings.language][label] || label;
						val = props.form._form.settings.translatedData[props.form._form.settings.language][val] || val;
					}
				}
			}
			if (props.component.disabled !== true) {
				state["value"]["defaultValue"] = val;
			} else {
				state["value"]["defaultValue"] = label;
			}
			if (props.component.disabled === true) {
				state["options"] = [{ label: label, value: label }];
			}
		}
		if (props.component.disabled !== true && !state.loading) {
			state["loading"] = true;
			props.setOldState(state);

			loadData();
		} else {
			props.setOldState(state);
		}
	}, []);

	//ON LOAD
	const loadData = () => {
		let state = props.oldState;

		if (props.component.requestUrl !== "" && props.component.requestUrl && props.oldState.options.length === 0) {
			var accessToken = encryptStorage1.getItem(Constants.AccessTokenKeyInLocalStorage);
			var headers = { "Content-Type": "application/json" };

			if (accessToken) {
				var headerToken = { Authorization: "Bearer " + accessToken };
				headers = { ...headers, ...headerToken };
			}

			fetch(props.component.requestUrl, {
				method: "GET",
				headers: { ...headers },
			})
				.then((response) => response.json())
				.then((res) => {
					if (res) {
						let path = props.component.dataPath;
						let result = path ? _.get(res, path, "") : res;
						let val = [];
						if (result) {
							for (let i = 0; i < result.length; i++) {
								if (props.component.labelsName && props.component.labelsName.length !== 0) {
									let labelName = "";

									for (let j = 0; j < props.component.labelsName.length; j++) {
										labelName = labelName + " " + result[i][props.component.labelsName[j].gridLabelName];
									}
									result[i].label = labelName;
								} else {
									result[i].label = result[i][props.component.labelName];
								}
								result[i].value = result[i][props.component.valueName];
							}
						}
						val = result;

						if (val.length > 0) {
							state["mainData"] = val;
						}
						setOptions(state);
					}
				})
				.then(() => {})
				.catch((error) => {
					state["mainData"] = [];
					props.setOldState(state);
				});
		}
	};

	const setOptions = (state) => {
		let allData = [];

		if (state["mainData"].length > 0 && state.loading && props.oldState.options.length === 0) {
			for (let i = 0; i < state["mainData"].length; i++) {
				if (props.form._form.settings) {
					let lan = props.form._form.settings.language || "en";
					allData.push({
						label: props.form._form.settings.translatedData
							? props.form._form.settings.translatedData[lan]
								? props.form._form.settings.translatedData[lan][state["mainData"][i].label] || state["mainData"][i].label
								: state["mainData"][i].label
							: state["mainData"][i].label,
						value: state["mainData"][i].value,
					});
					if (props.component.keysToAdd) {
						for (let j = 0; j < props.component.keysToAdd.length; j++) {
							allData[i][props.component.keysToAdd[j].fieldApi] = state["mainData"][i][props.component.keysToAdd[j].fieldApi];
						}
					}
				}
			}

			state["loading"] = false;

			if (allData.length > 0) {
				state["options"] = allData;
				props.setOldState(state);
				props.form.triggerRedraw();
			} else {
				props.setOldState(state);
			}
		}
	};

	//add page data in global form data
	useEffect(() => {
		if (props.data) {
			let keys = Object.keys(props.data);
			for (let i = 0; i < keys.length; i++) {
				props.form._data[keys[i]] = props.data[keys[i]];
				props.form._submission.data[keys[i]] = props.data[keys[i]];
			}
			if (props.insideGrid === true) {
				delete props.form._data[props.component.key];
				delete props.form._submission.data[props.component.key];
			}
		}
	}, [props.data]);
	useEffect(() => {
		let state = props.oldState;
		if (props.component.initialValue && props.oldState.options.length > 0 && !state.initialValueSet) {
			let val = props.oldState.options.filter((v) => v.value + "" === props.component.initialValue + "");
			if (val.length > 0) {
				state.initialValueSet = true;
				props.setOldState(state);
				updateValue({ target: { value: val[0].value } });
			}
		}
	}, [props.oldState.options]);

	return (
		props.component.initialShow && (
			<>
				<CustomSelect
					component={props.component}
					disabled={props.component.disabled === true}
					value={props.oldState["value"].defaultValue}
					options={props.oldState["options"]}
					updateValue={updateValue}
				/>
			</>
		)
	);
};

export default class NormalSelect extends ReactComponent {
	static shouldSetValue = false; // Define shouldSetValue as a static property

	/**
	 * This is the first phase of component building where the component is instantiated.
	 *
	 * @param component - The component definition created from the settings form.
	 * @param options - Any options passed into the renderer.
	 * @param data - The submission data where this component's data exists.
	 */

	constructor(component, options, data) {
		options["oldState"] = {
			options: [],
			value: {},
			requestUrl: "",
			mainData: [],
			girdRefreshed: false,
			loading: false,
			initialValueSet: false,
			initializedInsideGrid: false,
		};
		super(component, options, data);
		this.reactInstance = null;
	}

	/**
	 * This function is the default settings for the component. At a minimum you want to set the type to the registered
	 * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
	 *
	 * @param sources
	 * @returns {*}
	 */
	static schema(...extend) {
		return ReactComponent.schema({
			type: "normalSelectCustomComp",
			label: "",
		});
	}
	static get builderInfo() {
		return {
			title: "Normal Select",
			icon: "cubes",
			group: "Basic",
			documentation: "",
			weight: -10,
			schema: NormalSelect.schema(),
		};
	}
	static editForm = settingsForm;
	/**
	 * The second phase of component building where the component is rendered as an HTML string.
	 *
	 * @returns {string} - The return is the full string of the component
	 */
	render() {
		// For react components, we simply render as a div which will become the react instance.
		// By calling super.render(string) it will wrap the component with the needed wrappers to make it a full component.
		return super.render(`<div ref="react-${this.id}"></div>`);
	}
	/**
	 * The third phase of component building where the component has been attached to the DOM as 'element' and is ready
	 * to have its javascript events attached.
	 *
	 * @param element
	 * @returns {Promise<void>} - Return a promise that resolves when the attach is complete.
	 */
	attach(element) {
		super.attach(element);
		let randomId = getRandomId();

		// The loadRefs function will find all dom elements that have the "ref" setting that match the object property.
		// It can load a single element or multiple elements with the same ref.
		this.loadRefs(element, {
			[`react-${randomId}`]: "single",
		});

		// if (this.refs[`react-${this.randomId}`]) {
		//     this.attachReact(this.refs[`react-${this.randomId}`], this.setReactInstance.bind(this));
		//     if (this.shouldSetValue) {
		//         this.setValue(this.dataForSetting);
		//         this.updateValue(this.dataForSetting);
		//     }
		// }
		return Promise.resolve();
	}

	/**
	 * Override this function to insert your custom component.
	 *
	 * @param element
	 * @param ref - callback ref
	 */
	attachReact(element, ref) {
		let insideGrid = false;
		let key = this.component.key;
		const rootForm = this.getRoot(); // Get the root form object
		const root = createRoot(element);
		let grid = "";

		Utils.eachComponent(
			rootForm.components,
			function (component) {
				if (component.component.type === "editgrid") {
					grid = component;

					Utils.eachComponent(
						component.component.components,
						function (component2) {
							if (!insideGrid) {
								insideGrid = component2.key === key;
							} else {
							}
						},
						true
					);
				}
			},
			true
		);
		if (insideGrid) {
			if (grid.editRows[this.rowIndex]?.state === "new" && !this.options["oldState"].initializedInsideGrid) {
				this.component.defaultValue = null;
				grid.editRows[this.rowIndex].data[key] = null;
				this.options["oldState"].initializedInsideGrid = true;
				this.options["oldState"].rowIndex = this.rowIndex;
			} else {
				this.component.defaultValue = grid.editRows[this.rowIndex]?.data[key] || rootForm._data[this.rowIndex]?.data[key];
			}
		}
		const setOldState = (value) => {
			this.options["oldState"] = value;
			this.updateOnChange({}, true);
			root.render(
				<NormalSelectCustomComp
					component={this.component} // These are the component settings if you want to use them to render the component.
					onChange={this.updateValue} // Pass the onChange event handler
					value={this.dataValue}
					form={rootForm}
					data={this.data}
					element={this}
					insideGrid={insideGrid}
					setOldState={setOldState}
					oldState={this.options["oldState"]}
					updateOnChange={this.updateOnChange}
				/>
			);
		};

		root.render(
			<NormalSelectCustomComp
				component={this.component} // These are the component settings if you want to use them to render the component.
				onChange={this.updateValue} // Pass the onChange event handler
				value={this.dataValue}
				form={rootForm}
				element={this}
				data={this.data}
				insideGrid={insideGrid}
				setOldState={setOldState}
				oldState={this.options["oldState"]}
				updateOnChange={this.updateOnChange}
			/>
		);
	}
}
