import FAQ2 from "components/pages/landing/Faq";
import Landing from "components/pages/landing/Landing";
import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import ErrorLayout from "../layouts/ErrorLayout";
import MainLayout from "../layouts/MainLayout";


import Error404 from "components/errors/Error404";
import Error500 from "components/errors/Error500";

//import CreateUser from 'components/CreateUser';
import LoginActivity from "components/LoginActivity";
import ManageUsers from "components/ManageUsers";
//import CreateRole from 'components/CreateRole';
import ManageRoles from "components/ManageRoles";
import ManageRelatedCompanies from "components/custom/relatedCompanies/ManageRelatedCompanies";
import ManageRelatedGenerators from "components/custom/relatedGenerators/ManageRelatedGenerators";

import AddForm from "components/forms/AddForm";
import EditForm from "components/forms/EditForm";
import FormBuilderSchema from "components/forms/FormBuilderSchema";
import FormRenderer from "components/forms/FormRenderer";
import FormSubmission from "components/forms/FormSubmission";
import FormViewer from "components/forms/FormViewer";
import ManageForms from "components/forms/ManageForms";


import Redash from "components/customDashboard/Redash";
//import SubmissionsList from 'components/forms/SubmissionsList';

import FormViewRenderer from "components/forms/FormViewRenderer";
import GetWorkflowListByAssignee from "components/workflow/GetWorkflowListByAssignee";
import GetWorkflowListHistoryByAssignee from "components/workflow/GetWorkflowListHistory";
import WorkflowDiagramViewer from "components/workflow/WorkflowDiagramViewer";
import WorkflowHistoryView from "components/workflow/WorkflowHistoryView";
import WorkflowList from "components/workflow/WorkflowList";
import WorkflowModeler from "components/workflow/WorkflowModeler";
import WorkflowModelerEditMode from "components/workflow/WorkflowModelerEditMode";
import { EncryptStorage } from "encrypt-storage";

import * as Constants from "common/constants";
import LoadIFrame from "components/custom/LoadIframe/LoadIFrame";
import ManageContacts from "components/custom/relatedGenerators/ManageContacts";
import ManageTrucks from "components/custom/relatedGenerators/ManageTrucks";
import AboutUs from "components/pages/landing/AboutUs";
import Contact from "components/pages/landing/Contact";
import Manuals from "components/pages/landing/Manuals";
import Privacypolicy from "components/pages/landing/Privacypolicy";
import GetWorkflowListByCandidateGroup from "components/workflow/GetWorkflowListByCandidateGroup";
import ManageTemplates from "components/workflow/ManageTemplates";
import { parseQueryParams } from "components/workflow/WorkflowHelper";
import db from "indexedDB";

const FalconRoutes = () => {
	const encryptStorage1 = new EncryptStorage("secret-key-value", {
		prefix: "@mwan",
	});
	const location = useLocation();
	const [hasPermission, setHasPermission] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const navigate = useNavigate();

	//clear indexedDB temp_form_data (last filled form data) when pathname changes
	useEffect(() => {
		const clearTempData = async () => {
			await db.table("temp_form_data").clear();
		};
		try {
			if (sessionStorage.getItem(Constants.HasRedirected) && location.pathname !== "/form_view_renderer") {
				db.table("current_workflow_step").clear();
				localStorage.removeItem(Constants.CurrentWorkflowStep);
			}
			if (location.pathname !== "/form_view_renderer" && location.pathname.indexOf("/formView") < 0) {
				// clearTempData();
			}
			if (location.pathname === "/form_view_renderer") {
				setHasPermission(true);

				setIsLoading(false);
			} else {
				var userInfo = encryptStorage1.getItem(Constants.LoggedInUserInfoKeyInLocalStorage);
				var selectedCompany = encryptStorage1.getItem(Constants.SelectedCompany);
				Object.keys(userInfo.userCompanyDictionary).forEach((key) => {
					if (selectedCompany === key) {
						userInfo.userCompanyDictionary[key].forEach((item) => {
							for (let j = 0; j < item.permissionResponseList.length; j++) {
								for (let k = 0; k < item.permissionResponseList[j].menuResponseList.length; k++) {
									if (item.permissionResponseList[j].menuResponseList[k].to === location.pathname) {
										setIsLoading(false);
										setHasPermission(true);
										break;
									}
								}
							}
							setIsLoading(false);
						});
					}
				});
			}
		} catch (e) {}

		// if ("serviceWorker" in navigator && "load" in window) {
		// 	navigator.serviceWorker.getRegistration().then((registration) => {
		// 		if (registration) {
		// 			const currentURL = window.location.href;
		// 			window.location.href = currentURL;
		// 		}
		// 	});
		// }
	}, [location]);
	//
	useEffect(() => {
		const currentUrl = window.location.href;

		var queryParams = parseQueryParams(currentUrl);

		if (sessionStorage.getItem(Constants.HasRedirected) === null && !queryParams.generatedEmanifestId) {
			if (localStorage.getItem(Constants.CurrentWorkflowStep)) {
				navigate("/userTasks");
			}
		}
	}, []);
	return hasPermission ? (
		<Routes>
			{window.location.host.includes("emanifest") || window.location.host.includes("insight2") ? <Route path="/" element={<Landing />} /> : <></>}
			<Route path="landing" element={<Landing />} />
			<Route path="privacypolicy" element={<Privacypolicy />} />
			<Route path="aboutus" element={<AboutUs />} />
			<Route path="manuals" element={<Manuals />} />
			<Route path="faq" element={<FAQ2 />} />
			<Route path="contact" element={<Contact />} />
			<Route element={<ErrorLayout />}>
				<Route path="errors/404" element={<Error404 />} />
				<Route path="errors/500" element={<Error500 />} />
			</Route>
			{!window.location.href.includes("inspection") ? <Route path="/" element={<Landing />} /> : <></>}
			{/*- ------------- Authentication ---------------------------  */}

			<Route element={<MainLayout />}>
				{/*<Route path="createUser" element={<CreateUser />} />*/}
				{/*<Route path="createRole" element={<CreateRole />} />*/}
				{window.location.href.includes("inspection") ? <Route path="/" element={<GetWorkflowListByAssignee />} /> : <></>}

				<Route path="manageUsers" element={<ManageUsers />} />
				<Route path="manageRoles" element={<ManageRoles />} />
				<Route path="loginActivity" element={<LoginActivity />} />

				<Route path="/manageTransporters" element={<ManageRelatedCompanies RelatedCompanytypeID="3" CompanyRelationshipTypeID="1" />} />
				<Route path="/manageFacilities" element={<ManageRelatedCompanies RelatedCompanytypeID="2" CompanyRelationshipTypeID="1" />} />

				<Route path="/manageGenerators" element={<ManageRelatedGenerators RelatedCompanytypeNamePlural="Generators" CompanyID="1" />} />
				<Route path="/manageContacts" element={<ManageContacts RelatedCompanytypeNamePlural="Contacts" RelatedCompanytypeID="2" CompanyRelationshipTypeID="1" />} />
				<Route path="/manageFleet" element={<ManageTrucks RelatedCompanytypeNamePlural="Fleet" CompanyRelationshipTypeID="1" />} />
			</Route>

			{/*- ------------- Forms ---------------------------  */}
			<Route element={<MainLayout />}>
				<Route path="manageForms" element={<ManageForms />} />
				<Route path="addForm" element={<AddForm />} />
				<Route path="formrender/:id" element={<FormRenderer />} />
				<Route path="formedit/:id" element={<EditForm />} />
				<Route path="formsubmission/:path" element={<FormSubmission />} />
				<Route path="formbuilderschema" element={<FormBuilderSchema />} />
				<Route path="formView/:id" element={<FormViewer />} />
			</Route>

			{/*- ------------- Workflows ---------------------------  */}
			<Route element={<MainLayout />}>
				<Route path="workflowList" element={<WorkflowList />} />
				<Route path="workflowModeler" element={<WorkflowModeler />} />
				<Route path="form_view_renderer" element={<FormViewRenderer />} />
				<Route path="workflowDiagramViewer" element={<WorkflowDiagramViewer />} />
				<Route path="workflowModelerEditMode" element={<WorkflowModelerEditMode />} />
				<Route path="userTasks" element={<GetWorkflowListByAssignee />} />
				<Route path="unassignedTasks" element={<GetWorkflowListByCandidateGroup />} />

				<Route path="workflowHistory" element={<GetWorkflowListHistoryByAssignee />} />
				<Route path="workflow-history-view" element={<WorkflowHistoryView />} />
				<Route path="manageTemplates" element={<ManageTemplates />} />
			</Route>

			{/*- ------------- Load IFrame ---------------------------  */}
			<Route element={<MainLayout />}>
				<Route path="loadIframe" element={<LoadIFrame />} />
				<Route path="loadIframeCrm" element={<LoadIFrame />} />
				<Route path="loadIframeBeaati" element={<LoadIFrame />} />
				<Route path="loadIframeSurveyed" element={<LoadIFrame />} />
			</Route>

			{/* //--- MainLayout Starts  */}

			<Route element={<MainLayout />}>
				{/*Dashboard*/}
				<Route path="/" element={<Redash isMain={true} />} />
				<Route path="/dashboard/dashboards" element={<Redash isDashboardsPressed={true} />} />
				<Route path="/dashboard/queries" element={<Redash isQueriesPressed={true} />} />
				<Route path="/dashboard/settings" element={<Redash isSettingsPressed={true} />} />

			</Route>

			{/* //--- MainLayout end  */}

			{/* <Navigate to="/errors/404" /> */}
			<Route path="*" element={<Navigate to="/errors/404" replace />} />
		</Routes>
	) : isLoading === false ? (
		<Error404 />
	) : (
		<Routes>
			<Route path="landing" element={<Landing />} />
		</Routes>
	);
};

export default FalconRoutes;
