import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { parse } from "date-fns";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableFooter from "components/common/advance-table/AdvanceTableFooter";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import {
	continueProcess,
	formatDate,
	getAllDatabaseRecords,
	getDatabaseRecord,
	getFormById,
	getTaskVariables,
	getWorkflowListByAssignee,
	getWorkflowListHistoryByAssignee,
	getWorkflowsHistoryByProcessInstance,
	getWorkflowsSubprocess,
	parseDateAndTime,
	printPDF,
	getFormByUserTask,
	putProcessInstanceVariable,
} from "./WorkflowHelper";
import {
	DeleteProcessInstance,
} from "common/constants";
import { GetFromEndPoint, SetAssigneeForTask } from "common/general";
import jQuery from "jquery";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import LoaderIcon from "components/custom/Preloader";
import "react-datepicker/dist/react-datepicker.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import IconButton from "components/common/IconButton";
import * as General from "common/general";
import { toast } from "react-toastify";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import * as em from "common/ErrorMessages";
import { EncryptStorage } from "encrypt-storage";
import * as Constants from "common/constants";
import UpliftCss from "../common/upliftcss.module.css";
import SearchPanel from "components/common/SearchPanel";
import { isNull } from "lodash";

function GetWorkflowListHistoryByAssignee() {
	const encryptStorage1 = new EncryptStorage("secret-key-value", {
		prefix: "@mwan",
	});

	const navigate = useNavigate();

	const [workflows, setWorkflows] = useState([]);
	const [isRTL, setIsRTL] = useState(JSON.parse(localStorage.getItem("isRTL")) || false);
	const [tableData, setTableData] = useState([]);
	const [originalData, setOriginalData] = useState([]);
	const [formReady, setFormReady] = useState(false);
	const [printingPDF, setPrintingPDF] = useState(false);
	const [t, i18n] = useTranslation();
	const [isCurrentUserEmail, setCurrentUserEmail] = useState("");
	const [task_id, settask_id] = useState("");
	const [processInstance_Id, setProcessInstance_Id] = useState("");
	const [table_name, settable_name] = useState("");
	const [modal, setModal] = useState(false);
	const [modalDriver, setModalDriver] = useState(false);
	const [task_name, settask_name] = useState("");
	const [processId, setProcessId] = useState("");
	const [fetchFlag, setFetchFlag] = useState(false);
	const toggle = () => setModal(!modal);
	const toggleDriver = () => setModalDriver(!modalDriver);
	const [driverCompany, setDriverCompany] = useState([]);
	const [driverEmail, setDriverEmail] = useState("");
	const [oldDriverEmail, setOldDriverEmail] = useState("");
	const [selectedDriver, setSelectedDriver] = useState("");
	const [oldDriverPhoneNumber, setOldDriverPhoneNumber] = useState("");
	const [oldDriverFullName, setOldDriverFullName] = useState("");
	const [newDriverPhoneNumber, setNewDriverPhoneNumber] = useState("");
	const [newDriverFullName, setNewDriverFullName] = useState("");
	const [truckCompany, setTruckCompany] = useState([]);
	const [selectedTruck, setSelectedTruck] = useState("");
	const [updatedTruckInfo, setUpdatedTruckInfo] = useState("");
	const [oldTruckPlateNumber, setOldTruckPlateNumber] = useState("");
	const [assigneeIdFieldName, setAssigneeIdFieldName] = useState("");
	const [createdId, setCreatedId] = useState("");
	const [isLoadingTaskId, setIsLoadingTaskId] = useState(false);
	const [isFinish, setIsFinish] = useState(false);
	const [errorNoChange, setErrorNoChange] = useState(false);
	const parseDate = (dateString) => parse(dateString, "dd-MM-yyyy HH:mm:ss", new Date());
	const location = useLocation();
	const menuName = t(location?.state?.pagename) || t("Process History");

	const [startDateFilter, setStartDateFilter] = useState();
	const [endDateFilter, setEndDateFilter] = useState();

	const [serverPageSize, setServerPageSize] = useState(10);
	const [serverCurrentPage, setServerCurrentPage] = useState(1);
	const [totalRecords, setTotalRecords] = useState(0);
	const [rowCount, setRowCount] = useState(10);
	const [isDataReady, setIsDataReady] = useState(false);

	const isInitialMount = useRef(true);
	const isStateRestored = useRef(false);

	const [searchParams, setSearchParams] = useState({
		searchTerm: "",
		startDate: "",
		endDate: "",
	});

	const [searchValues, setSearchValues] = useState({
		searchTerm: "",
		startDate: null,
		endDate: null,
	});

	
	
	const columns = [
		{
			Header: t("Process ID"),
			accessor: "generatedEmanifestID",
		},
		{
			Header: t("Workflow Name"),
			accessor: "workflowName",
		},
		{
			Header: t("Task Name"),
			accessor: "taskName",
		},
		{
			Header: t("Task Assignee"),
			accessor: "userTaskAssignee",
		},
		{
			Header: t("Creation Date"),
			type: "date",
			accessor: "creationDate",
			sortType: (rowA, rowB) => {
				const dateA = parseDate(rowA.values.creationDate);
				const dateB = parseDate(rowB.values.creationDate);
				return dateA - dateB;
			},
		},
		{ Header: t("Creation By"), accessor: "startUserId" },
		{
			Header: t("Modification Date"),
			accessor: "modificationDate",
			type: "date",
			sortable: true,
			sortType: (rowA, rowB) => {
				const dateA = parseDate(rowA.values.modificationDate);
				const dateB = parseDate(rowB.values.modificationDate);
				return dateA - dateB;
			},
		},
		{ Header: t("Status"), accessor: "status" },
		{ Header: t("Action"), accessor: "action" },
	];

	
	
	const handleServerPagination = (newPageSize) => {
		setServerPageSize(newPageSize);
		setServerCurrentPage(1);
		setFetchFlag((prev) => !prev);
		
		const paginationState = {
		  currentPage: 1,
		  pageSize: newPageSize,
		  searchParams,
		  totalRecords
		};
		sessionStorage.setItem('workflowPaginationState', JSON.stringify(paginationState));
	  };

	  const handleNextPage = () => {
		const nextPage = serverCurrentPage + 1;
		setServerCurrentPage(nextPage);
		setFetchFlag((prev) => !prev);
		
		const paginationState = {
		  currentPage: nextPage,
		  pageSize: serverPageSize,
		  searchParams,
		  totalRecords
		};
		sessionStorage.setItem('workflowPaginationState', JSON.stringify(paginationState));
	  };

	  const handlePreviousPage = () => {
		const prevPage = serverCurrentPage - 1;
		setServerCurrentPage(prevPage);
		setFetchFlag((prev) => !prev);
		
		const paginationState = {
		  currentPage: prevPage,
		  pageSize: serverPageSize,
		  searchParams,
		  totalRecords
		};
		sessionStorage.setItem('workflowPaginationState', JSON.stringify(paginationState));
	  };

	    useEffect(() => {
			const paginationState = {
			  currentPage: serverCurrentPage,
			  pageSize: serverPageSize,
			  searchParams,
			  totalRecords
			};
			sessionStorage.setItem('workflowPaginationState', JSON.stringify(paginationState));
		  }, [searchParams]);

	
		  useEffect(() => {
			const restoreState = () => {
			  const returningState = sessionStorage.getItem('returningFromForm');
			  if (returningState && !isStateRestored.current) {
				const paginationState = JSON.parse(returningState);
				
				Promise.resolve().then(() => {
				  setServerCurrentPage(paginationState.currentPage);
				  setServerPageSize(paginationState.pageSize);
				  setSearchParams(paginationState.searchParams);
				  setTotalRecords(paginationState.totalRecords);
				  
				  setSearchValues({
					searchTerm: paginationState.searchParams.searchTerm || "",
					startDate: paginationState.searchParams.startDate ? 
					  moment(paginationState.searchParams.startDate, "DD-MM-YYYY").toDate() : null,
					endDate: paginationState.searchParams.endDate ? 
					  moment(paginationState.searchParams.endDate, "DD-MM-YYYY").toDate() : null
				  });
				});
				
				isStateRestored.current = true;
				sessionStorage.removeItem('returningFromForm');
			  }
			  isInitialMount.current = false;
			};
		  
			restoreState();
		  }, []);

	const filterdata = (data) => {
		let filter = [];
		data.map((workflow) => {
			const searchgeneratedEmanifestID = t(workflow.generatedEmanifestID);
			const searchworkflowName = t(workflow.workflowName);
			const searchuserTaskAssignee = t(workflow.userTaskAssignee);
			const searchtaskName = t(workflow.taskName);
			if (startDateFilter && endDateFilter) {
				if (moment(workflow.creationDate, "DD-MM-YYYY").isBetween(moment(startDateFilter, "DD-MM-YYYY"), moment(endDateFilter, "DD-MM-YYYY"), null, "[]")) {
					filter.push(workflow);
				}
			} else if (startDateFilter && !endDateFilter) {
				if (moment(workflow.creationDate, "DD-MM-YYYY").isSameOrAfter(moment(startDateFilter, "DD-MM-YYYY"))) {
					filter.push(workflow);
				}
			} else if (!startDateFilter && endDateFilter) {
				if (moment(workflow.creationDate, "DD-MM-YYYY").isSameOrBefore(moment(endDateFilter, "DD-MM-YYYY"))) {
					filter.push(workflow);
				}
			} else {
				filter.push(workflow);
			}
		});

		return filter;
	};
	useEffect(() => {
		let NewData = filterdata(originalData);
		setTableData(NewData);
		setWorkflows(NewData);
	}, [startDateFilter, endDateFilter]);



	useEffect(() => {
		var actionEditDriver = encryptStorage1.getItem(Constants.LoggedInUserInfoKeyInLocalStorage);
		var selectedCompany = encryptStorage1.getItem(Constants.SelectedCompany);

		var actionListUser = [];
		var enableModifyStatus = false;

		Object.keys(actionEditDriver.userCompanyDictionary).forEach((key) => {
			if (selectedCompany === key) {
				actionEditDriver.userCompanyDictionary[key].forEach((item) => {
					for (let j = 0; j < item.permissionResponseList.length; j++) {
						for (let k = 0; k < item.permissionResponseList[j].actionsResponseDTO.length; k++) {
							actionListUser.push(item.permissionResponseList[j].actionsResponseDTO[k].actionId);
						}
					}
				});
			}
		});
		if (actionListUser.includes("editDriver")) {
			enableModifyStatus = true;
		}

		const fetchData = async () => {
			if (isInitialMount.current || isStateRestored.current) {
				isStateRestored.current = false;
				return;
			  }

			setIsDataReady(false);
			setIsFinish(true);
			try {
				// Retrieve user information and selected company from encrypted storage
				const userInfo = encryptStorage1.getItem(Constants.LoggedInUserInfoKeyInLocalStorage);
				const loggedInUser = userInfo?.userId || "";
				setCurrentUserEmail(loggedInUser);

				const selectedCompany = encodeURIComponent(encryptStorage1.getItem(Constants.SelectedCompany));
				const platform = localStorage.getItem(Constants.platform);

				// Fetch workflow list history by assignee
				const workflowList = await getWorkflowListHistoryByAssignee(
					loggedInUser,
					platform,
					selectedCompany,
					enableModifyStatus,
					serverCurrentPage,
					serverPageSize,
					searchParams.searchTerm,
					searchParams.startDate,
					searchParams.endDate
				);

				const sortedWorkflows = workflowList?.finalGetWorkflowsHistoryByAssigneeDTO || [];

				const totalCount = workflowList?.totalCount || 0;
				setTotalRecords(totalCount);
			//	console.log("ss", sortedWorkflows);
				// Transform the workflow data
				const pendingTasks = sortedWorkflows.flatMap((task) => {
					try {
						// Parse task if it's a string
						const parsedTask = typeof task === "string" ? JSON.parse(task) : task;

						// Populate parsedTask with additional properties
						return {
							...parsedTask,
							generatedEmanifestID: parsedTask.generatedEmanifestID ? t(parsedTask.generatedEmanifestID) : "",
							workflowName: parsedTask.workflowName ? t(parsedTask.workflowName) : "",
							taskName: parsedTask.taskName ? t(parsedTask.taskName) : "",
							userTaskAssignee: parsedTask.userTaskAssignee ? t(parsedTask.userTaskAssignee) : "",
							startUserId: parsedTask.startUserId ? t(parsedTask.startUserId) : "",
							status: parsedTask.status ? t(parsedTask.status) : "",
							index: task.id,
							action: (
								<>
									<OverlayTrigger
										overlay={
											<Tooltip style={{ position: "fixed" }} id="ViewTooltip">
												{t("View")}
											</Tooltip>
										}
									>
										<div className="theme-control-toggle-label icon-position end-text">
											<IconButton
												className={`btn btn-primary  ${UpliftCss.forceButtonColorwhite}`}
												iconClassName="fs--2"
												variant=""
												size="sm"
												icon="fa-solid fa-eye"
												style={{ margin: "auto 0.25rem" }}
												onClick={() => handleView(parsedTask.rootProcessInstanceId, parsedTask.applicationID, parsedTask.tableId, parsedTask.formKey)}
											/>
										</div>
									</OverlayTrigger>

									{parsedTask.status === "COMPLETED" && parsedTask.renderId !== null && parsedTask.renderId !== "" && (
										<OverlayTrigger
											overlay={
												<Tooltip style={{ position: "fixed" }} id="DownloadPdfTooltip">
													{t("Download PDF")}
												</Tooltip>
											}
										>
											<div className="theme-control-toggle-label icon-position end-text">
												<IconButton
													className="btn btn-primary"
													iconClassName="fs--2"
													variant=""
													size="sm"
													icon="fa-solid fa-download"
													style={{ margin: "auto 0.25rem" }}
													onClick={() => downloadPdf(parsedTask.rootProcessInstanceId, parsedTask.applicationID, parsedTask.workflowName, parsedTask.tableId)}
												/>
											</div>
										</OverlayTrigger>
									)}

									{parsedTask.ableToDelete == "True" && parsedTask.status !== "COMPLETED" && (
										<OverlayTrigger
											overlay={
												<Tooltip style={{ position: "fixed" }} id="abletodelete">
													{t(em.delete_icon)}
												</Tooltip>
											}
										>
											<div className="theme-control-toggle-label icon-position">
												<IconButton
													className="btn btn-danger"
													iconClassName="fs--2"
													variant=""
													size="sm"
													icon="fa-solid fa-trash"
													style={{ margin: "auto 0.25rem" }}
													onClick={() => openModalToDelete(parsedTask.rootProcessInstanceId, parsedTask.taskName, parsedTask.applicationID,parsedTask?.createdId)}
												/>
											</div>
										</OverlayTrigger>
									)}

									{parsedTask.status !== "COMPLETED" && parsedTask?.acceptEdit && parsedTask?.createdId  && (
										<OverlayTrigger
											overlay={
												<Tooltip style={{ position: "fixed" }} id="editdriver">
													{t("edit")}
												</Tooltip>
											}
										>
											<div className="theme-control-toggle-label icon-position">
												<IconButton
													className="btn btn-danger"
													iconClassName="fs--2"
													variant=""
													size="sm"
													icon="fa-solid fa-user-pen"
													style={{ margin: "auto 0.25rem" }}
													onClick={() =>
														openModalToChangeDriver(parsedTask)
													}
												/>
											</div>
										</OverlayTrigger>
									)}
								</>
							),
						};
					} catch (e) {
						console.error("Error parsing task:", task, e);
						return []; // Skip this task if it can't be parsed
					}
				});

				// Update state with the transformed tasks
				if (!originalData || originalData.length === 0) {
					setOriginalData(pendingTasks);
				}

				const recordsPerPage = pendingTasks?.length;
				setRowCount(recordsPerPage);

				setTableData(pendingTasks);
				setWorkflows(pendingTasks);
				setIsDataReady(true);
			} catch (error) {
				console.error("Error fetching data:", error);
			} finally {
				setIsFinish(false);
			}
		};

		fetchData();
	}, [fetchFlag, serverCurrentPage, serverPageSize, searchParams]);

	const openModalToDelete = (processInstanceId, name, applicationID,createdId) => {
		//id,tablename
		
		setModal(!modal);
		settask_name(name);
		setProcessInstance_Id(processInstanceId);
		settable_name(applicationID);
		setCreatedId(createdId);
	};


	const handleDelete = async () => {
		setModal(!modal);
		const response = await fetch(DeleteProcessInstance + processInstance_Id, {
			method: "DELETE",
		})
			//  if (response.ok) {
			//   const data =await response.json();
			.then((response) => response.json()) //response.json())
			.then((data) => {
				if (data.status === 1) {
					const EditSuccess = (responseObject) => {
						if (responseObject.status == Constants.SuccessStatus) {
							toast.success(t(em.deleted_successfully));
							setFetchFlag(true);
						}
					};
			
					const EditFailure = (error) => {};
			
						var jsonRequest = {
							
							databasePutData: {
								itemFieldName: "id",
								itemId: createdId,
								tableName: table_name,
								fields: {
									taskStatus: "Deleted",
								
			
								},
							},
						};
					if(createdId!=null && createdId!=""){
						General.PatchToEndPoint( Constants.PatchEndPoint, null, JSON.stringify(jsonRequest), EditSuccess, EditFailure);
					}else{
						setFetchFlag(true);
						toast.success(t(em.deleted_successfully));
							
					}		
					
				} else {
					toast.error(t(em.failed_delete_workflow));
				}
			})
			.catch((err) => {
				console.error(err.message);
			});
	};

	const openModalToChangeDriver = async (parsedTask) => {
		setErrorNoChange(false);
		setIsFinish(true);
	  
		try {
		  const {
			rootProcessInstanceId: processInstanceId,
			taskName: name,
			applicationID,
			userTaskAssignee,
			generatedEmanifestID,
			createdId,
			assignedUserId,
			truckInfo
		  } = parsedTask;
	  
		  const truckPlateNumber = truckInfo ? JSON.parse(truckInfo)?.plateNumber : null;
		  const selectCompany = encryptStorage1.getItem(Constants.SelectedCompany);
	  
		  // Fetch drivers list
		  const driversEndpoint = `${Constants.GetCompanyUsersData}&&tbl_users.tbl_roles_users.roleId=eq.TransporterDriver&&companyId=eq.${selectCompany}`;
		  const driversPromise = new Promise((resolve, reject) => {
			GetFromEndPoint(
			  driversEndpoint,
			  null,
			  null,
			  (response) => {
				const driverList = [];
				for (let i = 0; i < response.length; i++) {
				  if (response[i].rolesResponseList != "") {
					driverList.push(response[i]);
				  }
				}
				
				 // Find the original driver's information
				 const originalDriver = driverList.find(driver => driver.userId === assignedUserId);

				 if (originalDriver) {
				   setOldDriverPhoneNumber(originalDriver.mobileNumber || "");
				   setOldDriverFullName(originalDriver.fullName || "");
				 }

				setDriverCompany(driverList);
				setSelectedDriver(assignedUserId);
				setDriverEmail(assignedUserId);
				resolve();
			  },
			  (error) => reject(new Error('Failed to fetch drivers: ' + error))
			);
		  });
	  
		  // Fetch trucks list
		  const trucksEndpoint = `${Constants.GetTransporterTrucksData}${selectCompany}`;
		  const trucksPromise = new Promise((resolve, reject) => {
			GetFromEndPoint(
			  trucksEndpoint,
			  null,
			  null,
			  (response) => {
				const truckList = [];
				for (let i = 0; i < response.length; i++) {
				  if (response[i].rolesResponseList != "") {
					truckList.push(response[i]);
				  }
				}
				setTruckCompany(truckList);
				setSelectedTruck(truckPlateNumber);
				setOldTruckPlateNumber(truckPlateNumber);
				resolve();
			  },
			  (error) => reject(new Error('Failed to fetch trucks: ' + error))
			);
		  });
	  
		  // Fetch user field data
		  const emanifestEndpoint = `${Constants.GetAllEndPoint}/${applicationID}/&&id=eq.${createdId}`;
		  const userFieldPromise = new Promise((resolve, reject) => {
			GetFromEndPoint(
			  emanifestEndpoint,
			  null,
			  null,
			  (response) => {
				if (!response?.data?.dataBaseContent) {
				  reject(new Error('Invalid database content'));
				  return;
				}
	  
				const assigneeFieldName = response.data.dataBaseContent
				  .map(item => 
					Object.entries(item).find(([key, value]) => 
					  value && value.toString().toLowerCase().includes(assignedUserId?.toLowerCase())
					)?.[0]
				  )
				  .filter(Boolean);
	  
				setAssigneeIdFieldName(assigneeFieldName.toString());
				setCreatedId(createdId);
				resolve();
			  },
			  (error) => reject(new Error('Failed to fetch user field data: ' + error))
			);
		  });
	  
		  // Wait for all promises to resolve
		  await Promise.all([driversPromise, trucksPromise, userFieldPromise]);
	  
		  // Update state variables
		  setModalDriver(true);
		  settask_name(name);
		  setProcessId(generatedEmanifestID);
		  setProcessInstance_Id(processInstanceId);
		  settable_name(applicationID);
		  setOldDriverEmail(assignedUserId);
	  
		} catch (error) {
		  console.error('Error in openModalToChangeDriver:', error);
		  toast.error(t('Error loading driver and truck data'));
		} finally {
		  setIsFinish(false);
		}
	  };

	const handleTruckChange = (selectedPlateNumber) => {
		setSelectedTruck(selectedPlateNumber);
		setErrorNoChange(false);
		
		const selectedTruckInfo = truckCompany.find(truck => truck.plateNumber === selectedPlateNumber);

		if (selectedTruckInfo) {
			const newTruckInfo = {
				plateNumber: selectedTruckInfo.plateNumber,
				type: selectedTruckInfo.type,
				subType: selectedTruckInfo.subType,
				size: selectedTruckInfo.size,
				tareWeight: selectedTruckInfo.tareWeight,
				capacity: selectedTruckInfo.capacity,
				unit: selectedTruckInfo.capacityUnit
			};
	
			setUpdatedTruckInfo(JSON.stringify(newTruckInfo, null, 1));
		}
	};

	const handleDropDownChange = async () => {
		setIsLoadingTaskId(true);
		setErrorNoChange(false);
		try {
			const resTaskId = await getFormByUserTask(processInstance_Id);
			settask_id(resTaskId.getFormByTaskResponse.taskId);

			const selectedDriverId = document.getElementById("id_driverCompany").value;
        	setDriverEmail(selectedDriverId);

			const selectedDriverInfo = driverCompany.find(driver => driver.userId === selectedDriverId);

			if (selectedDriverInfo) {
				setNewDriverPhoneNumber(selectedDriverInfo.mobileNumber || "");
				setNewDriverFullName(selectedDriverInfo.fullName || "");
			}
		} catch (error) {
			console.error('Error fetching task ID:', error);
		} finally {
			setIsLoadingTaskId(false);
		}
	};

	const handletochangedriver = async () => {
		setIsFinish(true);
		let hasChanges = false;
		const userInfo = encryptStorage1.getItem(Constants.LoggedInUserInfoKeyInLocalStorage);
		const loggedInUserFullName = userInfo?.fullName || "";

		const variables = await getTaskVariables(processInstance_Id);
		const facilityName = variables.find((variable) => variable.name === "facilityInformation")?.value?.entityName;

		try {
			// Check if either driver or truck has changed
			const isDriverChanged = oldDriverEmail !== selectedDriver;
			const isTruckChanged = updatedTruckInfo && selectedTruck !== oldTruckPlateNumber;
	
			if (!isDriverChanged && !isTruckChanged) {
				setErrorNoChange(true);
				return;
			}
	
			setErrorNoChange(false);
	
			// Update driver if changed
			if (isDriverChanged && selectedDriver !== "") {
				if (!task_id) {
					throw new Error("Task ID missing for driver update");
				}
				if (!assigneeIdFieldName || !createdId) {
					throw new Error("Required fields missing for driver update");
				}

				const assigneeData = {
					taskId: task_id,
					userId: driverEmail,
					tableName: table_name,
					fieldName: assigneeIdFieldName,
					createdId,
					processId,
					userInfo: {
						loggedInUserFullName: loggedInUserFullName
					},
					driverInfo: {
						oldDriver: {
							fullName: oldDriverFullName,
							phoneNumber: oldDriverPhoneNumber
						},
						newDriver: {
							fullName: newDriverFullName,
							phoneNumber: newDriverPhoneNumber
						}
					},
					selectedCompany: facilityName
				};

				const res = await SetAssigneeForTask(assigneeData);
				hasChanges = hasChanges || res;
			}
	
			// Update truck if changed
			if (isTruckChanged && updatedTruckInfo) {
				if (!createdId) {
					throw new Error("CreatedId missing for truck update");
				}
				
				// First update process instance
				await putProcessInstanceVariable(processInstance_Id, "truckInfo", {
					"value": updatedTruckInfo,
					"type": "Object",
					"valueInfo": {
						"objectTypeName": "java.util.LinkedHashMap",
						"serializationDataFormat": "application/json"
					}
				});
	
				// Then update supabase
				await new Promise((resolve, reject) => {
					General.PatchToEndPoint(
						Constants.PatchEndPoint,
						{},
						{
							"DatabasePutData": {
								TableName: table_name,
								Fields: { truckInfo: updatedTruckInfo },
								ItemId: createdId,
								ItemFieldName: "id"
							},
							"Lanuage": "en"
						},
						resolve,
						reject
					);
				});
	
				hasChanges = true;
			}
	
			if (hasChanges) {
				setModalDriver(false);
				setFetchFlag(prev => !prev); 
				toast.success(t("Changes saved successfully"));
			}
	
		} catch (error) {
			console.error("Error updating:", error);
			toast.error(t(error.message || "Error occurred while updating information"));
		} finally {
			setIsFinish(false);
		}
	};

	const handleView = async (processInstanceId, applicationID, tableId, formKey) => {
		// let record = await getDatabaseRecord(applicationID, processInstanceId);

		// if (record === null || record === undefined || record === "") {
		//   return;
		// }
		setIsFinish(true);
		let variables = await getTaskVariables(processInstanceId);
		var result = {};
		if (variables.find((variable) => variable.name === "recordInTableId") !== undefined) {
			result = await getDatabaseRecord(applicationID, "id", tableId);
		} else {
			result = variables.reduce((result, item) => {
				result[item.name] = item.value;
				return result;
			}, {});
		}
		if (jQuery.isEmptyObject(result)) {
			result = await getDatabaseRecord(applicationID, "id", tableId);
		}
		console.log("resullt", result);
		//changed this
		//let stepPlusOne = parseInt(result.step, 10) - 1;

		// let stepPlusOne = parseInt(result.step, 10) - 1 === 0 ? 1 : parseInt(result.step, 10) - 1;
		// if (stepPlusOne.toString() === "NaN") {
		// 	stepPlusOne = null;
		// }
		var form = null;
		console.log("fom1:", formKey);
		if (result.formKey) {
			form = await getFormById(result.formKey, null, result, true, 1);
		} else if (formKey !== null) {
			form = await getFormById(formKey, null, result, true, 1);
		} else {
			form = { status: 0 };
			setIsFinish(false);
		}
		if (form.status === 0) {
			return;
		}
		const formData = {
			...JSON.parse(form.data.formData),
		};

		setIsFinish(false);
		navigate("/workflow-history-view", { state: { data: formData } });
	};

	function isValidJSON(str) {
		try {
			JSON.parse(str);
			return true;
		} catch (error) {
			return false;
		}
	}

	const downloadPdf = async (processInstanceId, applicationID, workflowName, tableId) => {
		let variables = await getTaskVariables(processInstanceId);
		var result = variables.reduce((result, item) => {
			result[item.name] = item.value;
			return result;
		}, {});
		if (jQuery.isEmptyObject(result)) {
			result = await getDatabaseRecord(applicationID, "id", tableId);
		}

		if (result.renderId) {
			GetRender(result.renderId);
		}
	};

	const GetRender = (renderId) => {
		var downloadWindow = window.open(renderId);

		// Close the tab after 3 seconds (adjust the timeout as needed)
		setTimeout(function () {
			downloadWindow.close();
		}, 3000);
	};

	useEffect(() => {
		if (formReady) {
			setPrintingPDF(true);
			document.getElementById("workflowHistory").innerHTML = "";
			window.scrollTo(0, 0);

			setTimeout(() => {
				if (document.getElementsByClassName("loaderContainer")[0]) {
					document.getElementsByClassName("loaderContainer")[0].style.display = "none";
				}

				const containerElement = document.querySelector(".container");
				const children = Array.from(containerElement.children);

				children.forEach((child) => {
					if (!child.classList.contains("content")) {
						containerElement.removeChild(child);
					}
				});

				const contentElement = document.querySelector(".content");
				const contentChildren = Array.from(contentElement.children);

				contentChildren.forEach((child) => {
					if (!child.classList.contains("container")) {
						contentElement.removeChild(child);
					}
				});

				setPrintingPDF(false);

				window.print();
				window.location.reload();
			}, 3000);
		}
	}, [formReady]);

	return (
		<div className="container" style={{ display: "flex", flexDirection: "column" }}>
			<div className={`mb-3 card ${UpliftCss.marginTop25}`}>
				<div className={`card-body ${isRTL ? UpliftCss.gradiantTitleAr : UpliftCss.gradiantTitle}`}>
					<div className="col-md">
						<h5 className="mb-2 mb-md-0">{menuName}</h5>
					</div>
				</div>
			</div>
			{/* {printingPDF && <Loader />} */}
			<div id="workflowHistory" className="row">
				<div className="col-12">
					{/* <h1>Workflow List</h1>*/}
					<div className={`card ${UpliftCss.BodyBorder}`}>
						<div className="card-body">
							<div className={`mb-3 ${UpliftCss.tableHeader}`}>
								{isDataReady && (
									<AdvanceTableWrapper columns={columns} data={tableData} sortable pagination perPage={rowCount}>
										<SearchPanel
											onSearch={(filters) => {
												setSearchParams(filters);
												setServerCurrentPage(1); // Reset to first page when searching
												setFetchFlag((prev) => !prev);
											}}
											searchValues={searchValues}
											setSearchValues={setSearchValues}
										/>
										<AdvanceTable
											table
											headerClassName="bg-200 text-900 text-nowrap align-middle"
											rowClassName="align-middle white-space-nowrap"
											tableProps={{
												bordered: true,
												striped: true,
												className: "fs--1 mb-0 overflow-hidden",
											}}
										/>
										<div className="mt-3">
											<AdvanceTableFooter
												rowCount={totalRecords}
												pageSize={serverPageSize}
												pageIndex={serverCurrentPage - 1}
												page={tableData}
												setPageSize={handleServerPagination}
												canPreviousPage={serverCurrentPage > 1}
												canNextPage={serverCurrentPage * serverPageSize < totalRecords}
												nextPage={handleNextPage}
												previousPage={handlePreviousPage}
												rowInfo
												navButtons
												rowsPerPageSelection
												rowsPerPageOptions={[5, 10, 15]}
												perPage={rowCount}
											/>
										</div>
									</AdvanceTableWrapper>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* <div id="toPDF">
				<Formioform toPDF={true} setFormReady={(e) => setFormReady(e)} withWizard={false} form={form} />
			</div> */}
			{isFinish && <LoaderIcon />}
			<div>
				<Modal isOpen={modal} keyboard={true}>
					<ModalHeader toggle={toggle}>Delete!!</ModalHeader>
					<ModalBody>
						Are you sure you want to delete? <br />
						<br />
						<span style={{ color: "red", fontWeight: "bold" }}>{task_name}</span>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={() => handleDelete()}>
							Delete
						</Button>
						<Button color="secondary" onClick={toggle}>
							Cancel
						</Button>
					</ModalFooter>
				</Modal>
			</div>
			<div>
				<Modal isOpen={modalDriver} keyboard={true}>
					<ModalHeader toggle={toggleDriver}>{t("Edit Driver")}</ModalHeader>
					<ModalBody>
						<span style={{ color: "green", fontWeight: "bold" }}>{t(processId)}</span>
						<br />
						<br />
						{t("Select driver to change")} <br />
						<br />
						<div className="mb-3">
							<select
								name="drivercompany"
								id="id_driverCompany"
								className="form-select"
								value={selectedDriver}
								onChange={(e) => {
									//  setDriverCompany("");
									setSelectedDriver(e.target.value);
									handleDropDownChange();
								}}
							>
								{driverCompany.map((company) => (
									<option
										//  data-driverCompany={company.transporterCompanyId}
										//   data-name={company.transporterCompanyId}
										key={company.userId}
										value={company.userId}
									>
										{company.fullName}
									</option>
								))}
							</select>
						</div>
						<br />
						{t("Select truck to change")} <br />
						<br />
						<div className="mb-3">
							<select
								name="truckcompany"
								id="id_truckcompany"
								className="form-select"
								value={selectedTruck}
								onChange={(e) => {
									 handleTruckChange(e.target.value);
									 handleDropDownChange();
								}}
							>
								{truckCompany.map((truck) => (
									<option
										key={truck.plateNumber}
										value={truck.plateNumber}
									>
										{truck.plateNumber}
									</option>
								))}
							</select>
						</div>
						{errorNoChange && <span style={{ color: "red", fontWeight: "bold" }}>{t("You selected the same driver and truck! You need to either change one of them at least.")}</span>}
					</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={() => handletochangedriver()} disabled={isLoadingTaskId || !task_id}>
							{t("Change")}
						</Button>
						<Button color="secondary" onClick={toggleDriver}>
							{t("Cancel")}
						</Button>
					</ModalFooter>
				</Modal>
			</div>
		</div>
	);
}

export default GetWorkflowListHistoryByAssignee;
